import React, { Fragment } from 'react';
import Meta from '../components/Meta';
import { CookieDeclaration } from '../components/CookieDeclaration'

const Cookies = () => {
  return (
    <Fragment>
      <Meta pageTitle='Cookies' />
      <CookieDeclaration />
    </Fragment>
  );
}

export default Cookies

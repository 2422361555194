import React from "react";

const isBrowser = () => typeof window !== "undefined";

const COOKIEBOT_DECLARATION_WRAPPER_ID = "cookiebot-declaration-wrapper";

export function CookieDeclaration() {
  React.useEffect(() => {
    // Inject Cookiebot declaration
    if (isBrowser()) {
      const cookiebotCookieDeclarationScript = document.createElement("script");
      cookiebotCookieDeclarationScript.src =
        "https://consent.cookiebot.com/b9fd9b23-1d01-4e00-8b3b-1635be5e0eed/cd.js";
      cookiebotCookieDeclarationScript.type = "text/javascript";
      cookiebotCookieDeclarationScript.async = true;

      const cookiebotWrapperEl = document.getElementById(
        COOKIEBOT_DECLARATION_WRAPPER_ID
      );
      if (cookiebotWrapperEl) {
        cookiebotWrapperEl.appendChild(cookiebotCookieDeclarationScript);
      }
    }
  }, []);

  return (
    <div id="cookie-declaration-wrapper">
      <h5 class="h5 cookie-h5">Cookie Declaration</h5>
      <div id={COOKIEBOT_DECLARATION_WRAPPER_ID}></div>
    </div>
  );
}
